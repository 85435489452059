import { MAIN_LINK } from './links';

export const title = 'Tolq - AI-Driven Localization Service';
export const author = 'Tolq';
export const description =
  "Transform localization with Tolq's AI-driven solutions. Elevate your multilingual content and engage globally. Book a demo or start a free trial today!";
export const url = MAIN_LINK;
export const categories = ['localization', 'translation', 'AI'];
export const keywordsHomePage =
  'AI-Driven Localization, Multilingual Content, Localization Solutions, Global Engagement, Translation Technology, Book a Demo, Free Trial, Machine Translation, Expert Services, Content Localization';
export const keywordsSolutionsPage =
  'Global Potential, Localization Solutions, Cultural Sensitivities, Scalable Solutions, Seamless Workflow Automation, Start Free Trial, Translation Memory, Quality and Consistency, API Integrations';
export const keywordsProductsPage =
  "Empowering Global Journey, Tolq's Suite of Products, Machine Translation, Expert Translation Services, Localization Solutions, Content Creation, Book a Demo, Start Free Trial, AI + Human Translation, Translation Memory, API Integrations";
export const keywordsContactPage =
  'Connect With Us, E-commerce Internationalization, Technical Assistance, Linguistic Expertise, Request for Proposal, Book a Demo, Start Now, Submit NDA, Forge New Frontiers, Technology Partnerships';
export const keywordsTranslatorPage =
  'Elevate Translation Career, Language Potential, Translator Opportunities, Translation Tools, Streamlined Finances, Become a Translator, Personal Task Inbox, AI Autocomplete, Transparent Financials, Partnership Opportunities';
