export const TERMS_CONDITIONS_LINK = 'https://tolq.com/terms-conditions';
export const PRIVACY_POLICY_LINK = 'https://tolq.com/privacy-policy';
export const SUPPORT_LINK = 'mailto:customerservice@tolq.com';
export const FACEBOOK_LINK = 'https://tolq.com/facebook';
export const LINKEDIN_LINK = 'https://tolq.com/linkedin';
export const TWITTER_LINK = 'https://tolq.com/twitter';
export const MAIN_LINK = 'https://clients.tolq.com';
export const LANDING_PAGE_LINK = 'https://tolq.com';
export const CLIENTS_LINK = 'https://clients.tolq.com';
export const TRANSLATORS_LINK = 'https://translators.tolq.com';
export const TRANSLATORS_SIGN_IN_LINK = 'https://translators.tolq.com/sign_in';
export const TRANSLATORS_SIGN_UP_LINK = 'https://translators.tolq.com/sign_up';
export const TRANSLATORS_SIGN_UP_FACEBOOK_LINK = 'https://translators.tolq.com/auth/facebook';
export const TRANSLATORS_SIGN_UP_GOOGLE_LINK = 'https://translators.tolq.com/auth/google_oauth2';
export const TRANSLATORS_SIGN_UP_LINKEDIN_LINK = 'https://translators.tolq.com/auth/linkedin';

export const DOCS_LINK = 'http://docs.tolq.com/docs';
