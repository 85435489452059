'use client';

import { DataToast, Loading, ThemeSelector } from 'components';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import Head from './head';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { CACHE_TIME } from 'constants/constants';
import { useTranslations } from 'next-intl';
import { notifyException } from 'services/appsignal';
import persister from 'services/persister';
import queryClient from 'services/queryClient';

interface IGlobalErrorPage {
  error: Error;
  reset: () => void;
}

export default function GlobalErrorPage({ error }: IGlobalErrorPage) {
  const t = useTranslations();
  const handleError = useCallback(async () => {
    notifyException(error);
    toast.error(DataToast, {
      data: { content: t('messages.error.something_happen') }
    });
  }, [error, t]);

  useEffect(() => {
    handleError();
  }, [handleError]);

  return (
    <html>
      <Head />
      <body>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister,
            maxAge: CACHE_TIME,
            hydrateOptions: {},
            dehydrateOptions: {}
          }}
        >
          <ThemeSelector>
            <Loading />
          </ThemeSelector>
        </PersistQueryClientProvider>
      </body>
    </html>
  );
}
