import { MAIN_LINK } from 'constants/links';
import { author, description, title } from 'constants/seo';

export default function Head() {
  return (
    <head>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="UTF-8" />
      {/* Primary Meta Tags  */}
      <meta name="title" content={title} />
      <meta
        name="keywords"
        content="AI-driven localization solutions, Global engagement, Language barriers, Multilingual communication, Localization technology"
      />
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      {/* Article Meta Tags  */}
      <meta property="article:publisher" content={MAIN_LINK} />
      <meta property="article:section" content="Translation" />
      <meta
        property="article:tag"
        content="AI-driven localization, Global engagement, Language barriers, Cultural relevance, Scalable content, Localization technology, Multilingual communication, Translation accuracy, Seamless integration"
      />
      {/* Open Graph / Facebook  */}
      <meta property="og:image" content="<generated>" />
      <meta property="og:image:alt" content="<generated>" />
      <meta property="og:image:type" content="<generated>" />
      <meta property="og:image:width" content="<generated>" />
      <meta property="og:image:height" content="<generated>" />
      {/* Twitter  */}
      <meta name="twitter:image" content="<generated>" />
      <meta name="twitter:image:type" content="<generated>" />
      <meta name="twitter:image:width" content="<generated>" />
      <meta name="twitter:image:height" content="<generated>" />
      <meta property="twitter:image:alt" content="<generated>" />
      {/* Favicon  */}
      <link rel="icon" href="/images/favicon.ico" sizes="32x31" />
      <link rel="icon" href="/images/favicon.svg" type="image/svg+xml" />
      {/* <link rel="icon" href="/icon?<generated>" type="image/<generated>" sizes="<generated>" />
      <link
        rel="apple-touch-icon"
        href="/apple-icon?<generated>"
        type="image/<generated>"
        sizes="<generated>"
      /> */}
      {/* Scripts  */}
      <script id="stripe-js" src="https://js.stripe.com/v3/" async></script>
    </head>
  );
}
